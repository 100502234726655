<template>
  <div id="users" class="users container-fluid">
    <h1 class="page-header primary">
      Users
      <span v-if="users" class="f5">({{ users.length }})</span>
      <SearchBox class="fr f6" v-if="1" :searchList="users" />
    </h1>

    <router-link to="">
      <span>
        <button
          @click="performDelete"
          v-if="checkedIDs.length"
          type="button"
          id="trash"
          class="mr1"
          style="background: url(./font-awesome-svgs/trash-alt.svg); background-size: 18px 18px; width: 18px; height: 18px; border: 0px; margin-left: 5px;"
        />

        <span @click="performDelete" v-if="checkedIDs.length" class="mr4">
          Delete Selected <span class="f5">({{ checkedIDs.length }})</span>
        </span>
      </span>
    </router-link>

    <router-link to="/adduser">
      <button
        type="button"
        id="add"
        style="background: url(./font-awesome-svgs/plus-square.svg); background-size: 18px 18px; width: 18px; height: 18px; border: 0px; margin-left: 5px;"
      />
      Add User
    </router-link>

    <table class="table table-striped table-borderless table-sm">
      <thead>
        <tr class="bg-gold b">
          <th>
            <input
              type="checkbox"
              class=""
              v-model="deleteAllCheckBox"
              @change="toggleAllchecked($event)"
            />
          </th>
          <th class="th-sm">Name/ID</th>
          <th class="th-sm">Email</th>

          <th>Created</th>

          <th class="">Created by</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="u in users" :key="u.ID">
          <td>
            <input
              type="checkbox"
              :id="u.ID"
              :value="u.ID"
              class=""
              v-model="checkedIDs"
              :disabled="
                (getSessionObj.userID === u.OwnerID ||
                  isAdmin()) &&
                u.ID !== getSessionObj.userID
                  ? false // if owner or admin, don't disable the checkbox
                  : 'disabled'
              "
            />
          </td>

          <td class="">
            <router-link class="gray hover-gold" v-bind:to="'/users/' + u.ID">{{
              u.Name
            }}</router-link>
            <div class="f7">{{ u.ID }}</div>
          </td>

          <td>
            <a class="gray hover-gold" :href="'mailto:' + u.Email">{{
              u.Email
            }}</a>
          </td>

          <td>{{ u.CreatedAt | formatDate }}</td>

          <td>
            <router-link
              class="gray hover-gold"
              v-bind:to="'/users/' + u.OwnerID"
            >
              {{ u.OwnerName }}
            </router-link>
          </td>
          <!-- <td><button class="button f6  dim ph3 pv2 mb2 dib white bg-black" type="button"  v-on:click="deleteUser(u.ID)">Delete</button></td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { messageBus } from '@/main.js';
import Helpers from '@/helpers.js';
import moment from 'moment';
import SearchBox from '@/components/SearchBox.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Users',
  data() {
    return { deleteAllCheckBox: false, checkedIDs: [] };
  },
  computed: {
    ...mapGetters(['getUsers', 'getSessionObj']),
    users() {
      let myUsers = this.getUsers;
      myUsers.forEach((p) => {
        let user = this.getUsers.find((u) => u.ID === p.OwnerID);
        if (user) {
          p.OwnerName = user.Name;
        }
      });
      return myUsers;
    },
  },
  methods: {
    toggleAllchecked(event) {
      if (event.target.checked) {
        // select all
        this.users.forEach((u) => {
          if (
            this.checkedIDs.includes(u.ID) === false &&
            (this.getSessionObj.userID === u.OwnerID ||
              this.isAdmin()) &&
            u.ID !== this.getSessionObj.userID
          ) {
            this.checkedIDs.push(u.ID);
          }
        });
      } else {
        // unselect all
        this.checkedIDs = [];
      }
    },
    performDelete() {
      this.$bvModal
        .msgBoxConfirm('Are you sure?  This action can not be undone.')
        .then((value) => {
          console.log('DELETE CONFIRMATION VALUE: ', value);

          if (!value) {
            return;
          }

          this.checkedIDs.forEach((id) => {
            this.deleteUser(id);
          });
          this.checkedIDs = [];
          if (this.deleteAllCheckBox) {
            this.deleteAllCheckBox = false;
          }
        })
        .catch((err) => {
          // An error occurred
          // skip delete
          console.log(err);
        });
    },
    deleteUser(id) {
      console.log('deleting user');
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      let url = this.getSessionObj.apiURL;
      this.$http
        .delete(url + '/users/' + id, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function(response) {
            console.log('Success deleting user: ', response.body);
            //this.$router.push({path: '/users', query: {alert: 'User deleted'}})
            this.fetchUsers(); // slow way. TODO: just remove from local state users []
            Helpers.showAlert('User(s) deleted');
          },
          (response) => {
            // Fail
            console.log('Failed DELETE to /users:id', response.body);
            if (response.body) {
              Helpers.showAlert('ERROR: ' + response.body);
            } else {
              Helpers.showAlert('Failed DELETE to /users/id');
            }
          }
        );
    },
    handleAction(action) {
      // action is the message passed by the event emit
      console.log('handleAction called');
      switch (action) {
        case 'fetchUsers':
          this.fetchUsers();
          break;
        default:
          console.log('Users.vue Ignoring action: ${action}');
      } // end switch statement
    },
    handleUserDeletedAction(msg) {
      console.log('handleUserDeletedAction called');
      this.fetchUsers();
      Helpers.showAlert(msg);
    },
    fetchUsers() {
      this.$store.dispatch('apiGetData', {
        url: '/users',
        commit: true,
        stateProperty: 'users',
        responseProperty: 'Items',
        errorAlert: 'ERROR: Could not retrieve users',
      }).then((users) => {
        users.Items.sort((a, b) => a.Name.localeCompare(b.Name));
      });
    },
    isAdmin() {
      return Helpers.isAdmin();
    },
  }, // end methods
  filters: {
    formatDate(value) {
      if (value) {
        //return moment(String(value)).format('MM/DD/YYYY hh:mm')
        return moment(String(value)).format('YYYY-MM-DD');
      }
    },
  },
  created() {
    Helpers.doViewCreation(this);

    // Create message bus handling
    messageBus.$on('do-action', this.handleAction);
    messageBus.$on('user-deleted', this.handleUserDeletedAction);

    this.fetchUsers();
  },
  beforeCreated() {},
  updated() {},
  mounted() {},
  beforeRouteEnter(to, from, next) {
    // see https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-before-navigation
    next();
  },
  components: { SearchBox },
};
</script>

<style scoped>
#secure {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 20px;
  margin-top: 10px;
}

.identifier {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 5%;
  max-width: 5%;
}
</style>
