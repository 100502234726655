import axios from 'axios';
import Helpers from '@/helpers.js';

/**
 * Start of state
 */
// NOTE:  IF YOU ADD ANYTHING TO STATE, also add appropriate
// code to the RESET_STATE mutation
const state = {
  sessionInfo: {
    tokenPayload: null,
    access_token: '',
    roles: [],
    token_type: '',
    apiURL: '',
    username: '',
    expires_in: '',
    userID: '',
  },
  sessionPassword: '',
  authenticated: false,
  channels: [],
  users: [],
  projects: [],
  sprites: [],
  shapes: [],
  buildInfo: '',
  version: '',
  loadingUsers: false,
  loadingProjects: false,
  loadingChannels: false,
  loadingSprites: false,
  loadingShapes: false,
  loadingBuildInfo: false,
  loadingVersion: false,
  testArray: [
    { name: 'obj1', value: 1 },
    { name: 'obj2', value: 2 },
  ],
};

const getters = {
  getSessionObj: (state) => state.sessionInfo,
  isAuthenticated: (state) => state.authenticated,
  getSessionPassword: (state) => state.sessionPassword,
  getChannels: (state) => state.channels, // API: /channels
  getProjects: (state) => state.projects, // API: /projects
  getUsers: (state) => state.users, // API: /users
  getSprites: (state) => state.sprites, //API: /sprites
  getShapes: (state) => state.shapes, //API: /shapes
  getBuildInfo: (state) => state.buildInfo, // API: /buildinfo
  getTestArray: (state) => state.testArray,
  getVersion: (state) => state.version, // version of this web App
  getLoadingUsers: (state) => state.loadingUsers,
  getLoadingChannels: (state) => state.loadingChanenls,
  getLoadingProjects: (state) => state.loadingProjects,
  getLoadingSprites: (state) => state.loadingSprites,
  getLoadingShapes: (state) => state.loadingShapes,
  getLoadingBuildInfo: (state) => state.loadingBuildInfo,
  getLoadingVersion: (state) => state.loadingVersion,
};

const actions = {
  // Parameters:
  //  payload <OBJECT>
  //  {
  //    url: "/channels",           // OPTIONAL: url to do the GET on, REQUIRED unless querying the root URL
  //    commit: true                // OPTIONAL: true or false, depending on whether a commit should take place
  //    stateProperty: "channels"   // OPTIONAL/REQUIRED: state property to commit to, required if commit = true
  //    responseProperty: "Items"   // OPTIONAL: property of response object to put into the stateProperty
  //    statePropertyLoaderToToggle: // OPTIONAL: property to set to true, then false, while loading. This is for spinners
  //    successAlert: ""            // OPTIONAL: alert message to display if success
  //    errorAlert: ""              // OPTIONAL: alert message to display if failure
  //    eventToEmitOnSuccess:       // OPTIONAL: send an event when finished
  //  }
  // NOTE:  You can leave responseProperty undefined, and the full response.data will be assigned
  //        to the stateProperty value of the state.
  async apiGetData({ commit, state }, payload) {
    try {
      if (Helpers.isTokenExpired(state.sessionInfo.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      if (payload && payload.statePropertyLoaderToToggle) {
        // set boolean that we are doing work
        commit('MUTATE', {
          property: payload.statePropertyLoaderToToggle,
          with: true,
        });
      }

      let url = state.sessionInfo.apiURL;
      let config = {
        headers: { authorization: state.sessionInfo.access_token },
      };
      if (payload) {
        if (payload.url) {
          url += payload.url;
        }
      }
      let response = undefined;
      try {
        response = await axios.get(url, config);
      } catch (err) {
        console.log(err);
        throw 'Failed GET API call';
      }

      if (payload.commit) {
        if (payload.responseProperty) {
          commit('MUTATE', {
            property: payload.stateProperty,
            with: response.data[payload.responseProperty],
          });
        } else {
          commit('MUTATE', {
            property: payload.stateProperty,
            with: response.data,
          });
        }
      }
      if (payload && payload.statePropertyLoaderToToggle) {
        // set boolean that we are DONE doing work
        commit('MUTATE', {
          property: payload.statePropertyLoaderToToggle,
          with: false,
        });
      }
      if (payload && payload.successAlert) {
        Helpers.showAlert(payload.successAlert);
      }
      if (payload && payload.eventToEmitOnSuccess) {
        Helpers.emitEvent(payload.eventToEmitOnSuccess);
      }
      return response.data;
    } catch (error) {
      Helpers.showAlert(payload.errorAlert);
      throw error;
    }
  },
  async apiPutData({ commit, state }, payload) {
    try {
      if (Helpers.isTokenExpired(state.sessionInfo.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = state.sessionInfo.apiURL;
      let body = {};
      let config = {
        headers: { authorization: state.sessionInfo.access_token },
      };
      if (payload) {
        if (payload.url) {
          url += payload.url;
        }
        if (payload.body) {
          body = Object.assign({}, payload.body, body);
        }
      }
      let response = await axios.put(url, body, config);
      if (payload.commit) {
        commit('MUTATE', {
          property: payload.stateProperty,
          with: response.data[payload.responseProperty],
        });
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // Parameters:
  //  payload <OBJECT>
  //  {
  //    url: "/channels",           // OPTIONAL: url to do the GET on, REQUIRED unless querying the root URL
  //    commit: true                // OPTIONAL: true or false, depending on whether a commit should take place
  //    stateProperty: "channels"   // OPTIONAL/REQUIRED: state property to commit to, required if commit = true
  //    responseProperty: "Items"   // OPTIONAL: property of respones object to put into the stateProperty
  //    successAlert: ""            // OPTIONAL: alert message to display if success
  //    errorAlert: ""              // OPTIONAL: alert message to display if failure
  //  }
  async apiDeleteData({ commit, state }, payload) {
    try {
      if (Helpers.isTokenExpired(state.sessionInfo.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = state.sessionInfo.apiURL;
      let body = {};
      let config = {
        headers: { authorization: state.sessionInfo.access_token },
      };
      if (payload) {
        if (payload.url) {
          url += payload.url;
        }
        if (payload.body) {
          body = Object.assign({}, payload.body, body);
        }
      }
      let response = await axios.delete(url, body, config);
      if (payload.commit) {
        commit('MUTATE', {
          property: payload.stateProperty,
          with: response.data[payload.responseProperty],
        });
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};

// SET_
// ADD_
// REMOVE_
const mutations = {
  // Payload <OBJECT>
  //  {
  //    property: "channels"    // the state property to mutate
  //    with: <data>            // the data you want to mutate the property with
  //  }
  MUTATE: (state, payload) => {
    if (
      payload.property &&
      state[payload.property].constructor.name != 'Array' &&
      typeof state[payload.property] === 'object'
    ) {
      // do object copy
      state[payload.property] = Object.assign({}, payload.with);
    } else {
      state[payload.property] = payload.with;
    }
  },
  RESET_STATE: (state) => {
    state.sessionInfo.tokenPayload = null;
    state.sessionInfo.access_token = '';
    state.sessionInfo.roles = [];
    state.sessionInfo.token_type = '';
    state.sessionInfo.apiURL = '';
    state.sessionInfo.username = '';
    state.sessionInfo.expires_in = '';
    state.sessionInfo.userID = '';
    state.sessionPassword = '';
    state.authenticated = false;
    state.channels = [];
    state.users = [];
    state.projects = [];
    state.sprites = [];
    state.shapes = [];
    state.buildInfo = '';
    state.version = '';
    state.loadingUsers = false;
    state.loadingChannels = false;
    state.loadingProjects = false;
    state.loadingSprites = false;
    state.loadingShapes = false;
    state.loadingBuildInfo = false;
    state.loadingVersion = false;
    state.testArray = [
      { name: 'obj1', value: 1 },
      { name: 'obj2', value: 2 },
    ];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
