<template>
  <div id="login" class="">
    <!-- <h1 class="page-header">{{ msg }} </h1> -->

    <h1 class="primary b"><center>ACX Service Administration</center></h1>

    <div class="mw5 mw-100 center  ph3 ph5-ns br4">
      <form class="center">
        <div
          v-show="showConnectionSettings"
          class="bg-light-gray mw6 center pa4 br4"
        >
          <label for="apiURL" class="f6 b mr3 mb2 fl w3"
            >API URL <span class="normal black-60">(required)</span></label
          >
          <input
            id="apiURL"
            class="input-reset ba b--black-20 pa2 mb2 mr2 w-75"
            type="text"
            aria-describedby="name-desc"
            v-model="input.apiURL"
            required
            placeholder="localhost"
          />
          <!-- <small id="serverIP-desc" class="f6 black-60 mb2">Raw IP only. Do not include http or /</small> -->
        </div>

        <p class="pa0"></p>

        <div class="bg-light-gray mw6 center pa4 br4">
          <label for="username" class="f6 b mb2 mr3 fl w3"
            >Username <span class="normal black-60">(required)</span></label
          >
          <input
            id="username"
            class="input-reset ba b--black-20 pa2 mb2 mr2 w-75"
            type="text"
            name="username"
            autofocus
            v-model="input.username"
            placeholder=""
            required
            autocomplete="username"
            @keyup.enter="setFocusOnPasswordField()"
          />

          <p class="pa1"></p>

          <label for="password" class="f6 b mb2 mr3 fl w3"
            >Password <span class="normal black-60">(required)</span></label
          >
          <input
            id="password"
            ref="passwordField"
            class="input-reset ba b--black-20 pa2 mb2 w-75"
            :type="passwordFieldType"
            name="password"
            v-model="input.password"
            required
            autocomplete="password"
            placeholder=""
            @keyup.enter="login()"
          />
          <button
            id="eye"
            type="button"
            style="background: url(font-awesome-svgs/eye.svg); width: 20px; height: 18px; border: 0px; margin-left: 5px;"
            v-on:click="togglePasswordType()"
          />
        </div>

        <div class="center mw6 pt3">
          <center>
            <button
              class="f6 dim primary b bg-white center ph3 pv2 mb2 br3"
              type="button"
              v-on:click="login()"
            >
              Login
            </button>
            <small class="ml3"
              >Need help? Visit the
              <router-link
                v-if="!getSessionObj.authenticated"
                to="/support"
                class="dark-gray hover-gold b"
                >Support</router-link
              >
              page, or
              <span
                class="dark-gray hover-gold b"
                v-on:click="showConnectionSettings = !showConnectionSettings"
                >configure server</span
              ></small
            >
          </center>
        </div>
        <div v-show="spinner">
          <center>
            <i
              class="dib v-mid fa fa-gear fa-spin mh2"
              style="font-size:16px"
            ></i>
            <span class="f6 dib v-mid">{{ spinner_message }}</span>
          </center>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Helpers from '@/helpers.js';
import { mapGetters } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      input: {
        apiURL: '',
        username: '',
        password: '',
      },
      token: '',
      token_type: '',
      expires_in: '',
      user_id: '',
      tokenPayload: null,
      passwordFieldType: 'password',
      showConnectionSettings: false,
      spinner: false,
      spinner_message: 'connecting...',
    };
  },
  computed: mapGetters(['getSessionObj', 'isAuthenticated']),
  methods: {
    login() {
      Helpers.clearAlert();
      this.input.apiURL = this.input.apiURL.trim();
      this.input.username = this.input.username.trim();

      if (this.input.apiURL == '') {
        Helpers.showAlert(
          'Error: API Server URL is empty. Please configure connection settings.'
        );
      }

      if (this.input.username == '' || this.input.password == '') {
        console.log('A username and password must be present');
        Helpers.showAlert('Username and password must be present');

        // Can still save what the user has entered so far
        localStorage.apiURL = this.input.apiURL;

        return;
      }

      // Save what the user has entered in case they refresh the browser
      localStorage.apiURL = this.input.apiURL;

      this.spinner = true;

      // Base64 encode password
      let auth =
        'Basic ' +
        Buffer.from(this.input.username + ':' + this.input.password).toString(
          'base64'
        );

      // url for tokens is always the root url
      const myURL = new URL(this.input.apiURL);

      this.requestObject = this.$http
        .post(myURL.origin + '/tokens', '', {
          headers: { authorization: auth },
        })
        .then(
          function(response) {
            //console.log(response.body);
            //console.log(response.body.user_id);
            this.token_type = response.body.token_type;
            this.access_token = response.body.access_token;
            this.expires_in = response.body.expires_in;
            this.user_id = response.body.authenticated_user_id;

            // Now decode the token so we can get roles, and any other pertinent info from
            // the token. Tokens are in 3 parts, the payload is the second part.
            let encodedPayload = this.access_token.split('.')[1];
            let decodedPayload = JSON.parse(window.atob(encodedPayload));
            this.tokenPayload = decodedPayload;

            // assert this.input.username === this.tokenPayload.nam
            if (this.input.username != this.tokenPayload.nam) {
              // BIG PROBLEM.
              Helpers.showAlert('Error: please contact an administrator');
              this.spinner = false;
              return;
            }

            //this.alert = 'Authentication Success! id: ' + this.id + ', token: ' + this.token;
            this.$emit(
              'authenticated',
              true,
              this.tokenPayload,
              'Bearer ' + this.access_token,
              this.token_type,
              this.expires_in,
              this.input.apiURL,
              this.input.username,
              this.user_id
            );
            // NEW STORE
            let b64Password = Helpers.base64Encode(this.input.password);
            this.$store.commit('MUTATE', {
              property: 'sessionPassword',
              with: b64Password,
            });

            this.spinner = false;
            this.$router.replace({ name: 'home' });
          },
          (response) => {
            // Fail
            console.log('Failed POST to /tokens ', response.body);
            if (response.body) {
              Helpers.showAlert(
                'Error: ' +
                  response.bodyText +
                  ' ' +
                  response.status +
                  ' ' +
                  response.statusText
              );
            } else {
              Helpers.showAlert('Failed to connect to server');
            }
            this.spinner = false;
          }
        );
    }, // end login()
    togglePasswordType() {
      if (this.passwordFieldType === 'password') {
        document.getElementById('eye').style.backgroundImage =
          "url('font-awesome-svgs/eye-slash.svg')";
      } else {
        document.getElementById('eye').style.backgroundImage =
          "url('font-awesome-svgs/eye.svg')";
      }
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password';
    }, // end togglePasswordType
    setFocusOnPasswordField() {
      this.$refs.passwordField.focus();
    },
  }, // end methods
  created: function() {
    if (process.env.VUE_APP_API_URL) {
      this.input.apiURL = process.env.VUE_APP_API_URL;
    }

    Helpers.doViewCreation(this);

    this.$parent.doThemeThings();

    if (this.isAuthenticated) {
      this.$router.replace({ name: 'home' });
    }
  },
  mounted: function() {
    if (localStorage.apiURL) {
      this.input.apiURL = localStorage.apiURL;
    } else if (process.env.VUE_APP_API_URL) {
      this.input.apiURL = process.env.VUE_APP_API_URL;
    } else if (this.$parent.appDefaultApiUrl) {
      this.input.apiURL = this.$parent.appDefaultApiUrl;
    } else {
      this.input.apiURL = 'localhost';
    }
  }, // end mounted function
  components: {},
};
</script>

<style scoped></style>
