<template>
  <div class="home container-fluid">
    <h1 class="page-header primary">Home</h1>

    <div class="flex flex-wrap">
      <div class="bg-white w-third pr2 pv2 br3 white">
        <div class="bg-dark-blue br3 pv2">
          <router-link to="/users" class="no-underline">
            <div class="f4 ttu tc white">Users</div>
            <div v-if="getLoadingUsers" class="f3 tc white">
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <div v-else class="f3 tc white">{{ this.getUsers.length }}</div>
          </router-link>
        </div>
      </div>

      <div class="bg-white w-third pv2 br3 white no-underline">
        <div class="bg-dark-pink mh2 br3 pv2">
          <router-link to="/projects" class="no-underline">
            <div class="f4 ttu tc white no-underline">Projects</div>
            <div v-if="getLoadingProjects" class="f3 tc white">
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <div v-else class="f3 tc white">{{ this.getProjects.length }}</div>
          </router-link>
        </div>
      </div>

      <div class="bg-white w-third pl2 pv2 br3 white no-underline">
        <div class="bg-dark-green br3 pv2">
          <router-link to="/channels" class="no-underline">
            <div class="f4 ttu tc white no-underline">Channels</div>
            <div v-if="getLoadingChannels" class="f3 tc white">
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <div v-else class="f3 tc white">{{ this.getChannels.length }}</div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap">
      <div class="bg-gray w-100 pa4 pv4 mt2 br3 white">
        <!-- <router-link to="/sessioninfo" class="no-underline no-underline-hover"> -->
        <div class="ttu f4 tc white no-underline">API Service Version</div>
        <div v-if="getLoadingBuildInfo" class="f6 tc white">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-else class="f6 tc white">{{ this.getBuildInfo }}</div>
        <!-- </router-link> -->
        <br />
        <!-- <router-link to="/sessioninfo" class="no-underline no-underline-hover"> -->
        <div class="ttu f4 tc white no-underline">Web UI Version</div>
        <div v-if="getLoadingBuildInfo" class="f6 tc white">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-else class="f6 tc white">{{ this.getVersion }}</div>
        <!-- </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Helpers from '@/helpers.js';
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  data() {
    return {};
  },
  computed: mapGetters([
    'getChannels',
    'getProjects',
    'getUsers',
    'getBuildInfo',
    'getVersion',
    'getLoadingUsers',
    'getLoadingChannels',
    'getLoadingProjects',
    'getLoadingBuildInfo',
    'getLoadingVersion',
  ]),
  methods: {},
  created: function() {
    Helpers.doViewCreation(this);
  },
  beforeCreate: function() {},
  mounted: function() {},
  components: {},
};
</script>
