import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import resource from 'vue-resource'; // http client for vuejs, e.g. $http
import BootstrapVue from 'bootstrap-vue';
import VModal from 'vue-js-modal';
import './../node_modules/font-awesome/css/font-awesome.min.css';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './../node_modules/bootstrap-vue/dist/bootstrap-vue.min.css';
import './../node_modules/tachyons/css/tachyons.min.css';
import Verte from 'verte';
import 'verte/dist/verte.css';
import '@/assets/styles.css';

if (!process.env.VUE_APP_THEME) {
  console.log('No theme set.  Setting to default');
  process.env['VUE_APP_THEME'] = 'edxt';
}
console.log('Theme set to: ', process.env.VUE_APP_THEME);

// So can check later, as either development, or production
window['globalEnvMode'] = process.env;

Vue.config.productionTip = false;
Vue.use(resource);
Vue.use(BootstrapVue);
// Vue.use(VueClipboard);
Vue.use(VModal, { componentName: 'modal' });
Vue.component('verte', Verte);

// Create a global message bus for passing messages between peer components and views
export const messageBus = new Vue();

export const myVm = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
