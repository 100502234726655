<template>
  <div class="channels container-fluid">
    <h1 class="page-header primary">
      Channels
      <span v-if="channels" class="f5">({{ channels.length }})</span>
      <SearchBox class="fr f6" v-if="1" :searchList="channels" />
    </h1>

    <router-link to="">
      <span>
        <button
          @click="performDelete"
          v-if="checkedIDs.length"
          type="button"
          id="trash"
          class="mr1"
          style="background: url(./font-awesome-svgs/trash-alt.svg); background-size: 18px 18px; width: 18px; height: 18px; border: 0px; margin-left: 5px;"
        />

        <span @click="performDelete" v-if="checkedIDs.length" class="mr4">
          Delete Selected <span class="f5">({{ checkedIDs.length }})</span>
        </span>
      </span>
    </router-link>

    <router-link to="/addchannel">
      <button
        type="button"
        id="add"
        style="background: url(./font-awesome-svgs/plus-square.svg); background-size: 18px 18px; width: 18px; height: 18px; border: 0px; margin-left: 5px;"
      />
      Add Channel
    </router-link>

    <span @click="toggleAndSaveFilter" class="fr blue f7 pointer underline-hover">
      <span v-if="filterAll">(all)</span>
      <span v-else>(my channels)</span>
      <i class="fa fa-filter pl2 f4 gray" />
    </span>

    <table class="table table-striped table-borderless table-sm">
      <thead>
        <tr class="bg-gold b">
          <th>
            <input
              type="checkbox"
              class=""
              v-model="deleteAllCheckBox"
              @change="toggleAllchecked($event)"
            />
            <!-- </template> -->
          </th>
          <th class="th-sm">Name/ID</th>
          <th>Created By</th>
          <th>Created</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="u in channels" :key="u.ID">
          <td>
            <input
              type="checkbox"
              :id="u.ID"
              :value="u.ID"
              class=""
              v-model="checkedIDs"
              :disabled="
                getSessionObj.userID === u.OwnerID ||
                isAdmin(getSessionObj.userID)
                  ? false // if owner or admin, don't disable the checkbox
                  : 'disabled'
              "
            />
          </td>

          <td class="">
            <i
              v-if="u.IsLocked"
              class="fa fa-lock"
              @click="toggleChannelLock(u)"
            />
            <i v-else class="fa fa-unlock pr2" @click="toggleChannelLock(u)" />
            <router-link class="gray hover-gold" v-bind:to="'/channels/' + u.ID"
              >{{ u.Name }}
              <div class="f7">{{ u.ID }}</div>
            </router-link>
          </td>

          <td>
            <router-link
              class="gray hover-gold"
              v-bind:to="'/users/' + u.OwnerID"
              >{{ u.OwnerName }}</router-link
            >
          </td>

          <td>{{ u.CreatedAt | formatDate }}</td>

          <td>
            <router-link
              class="gray hover-gold"
              v-bind:to="'/channels/' + u.ID"
              >{{ u.Description }}</router-link
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import { messageBus } from '@/main';
import Helpers from '@/helpers.js';
import moment from 'moment';
import SearchBox from '@/components/SearchBox.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Channels',
  data() {
    return {
      deleteAllCheckBox: false,
      checkedIDs: [],
      filterAll: true,
    };
  },
  computed: {
    ...mapGetters(['getChannels', 'getUsers', 'getSessionObj']),
    channels() {
      let myChannels = this.getChannels;
      myChannels.forEach((c) => {
        let user = this.getUsers.find((u) => u.ID === c.OwnerID);
        if (user) {
          c.OwnerName = user.Name;
        }
      });

      if(!this.filterAll) {
        // filter down to ONLY projects where I'm owner
        return myChannels.filter( c => {return c.OwnerID === this.getSessionObj.userID })
      }

      return myChannels;
    },
  },
  methods: {
    eventHandler(topic, msg) {
      console.log('Received event for topic:' + topic + 'with msg: ', msg);
    },
    toggleChannelLock(channel) {
      console.log('setting channel lock to ', channel.IsLocked);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      if (
        this.getSessionObj.userID != channel.OwnerID &&
        !this.isAdmin()
      ) {
        return;
      }

      let url = this.getSessionObj.apiURL;
      let requestBody = {
        IsLocked: !channel.IsLocked,
        Name: channel.Name,
        Description: channel.Description,
      };
      this.$http
        .put(url + '/channels/' + channel.ID, requestBody, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function(response) {
            console.log(
              'Success setting channel lock status: ',
              response.bodyText
            );
            channel.IsLocked = !channel.IsLocked;
            // this.fetchChannels(); // slow way. TODO: just remove from local channels []
            if (channel.IsLocked) {
              Helpers.showAlert('Channel locked');
            } else {
              Helpers.showAlert('Channel unlocked');
            }
          },
          (response) => {
            // Fail
            console.log('Failed to PATCH to /channels:id', response.bodyText);
            if (response.body) {
              Helpers.showAlert('Error: ' + response.bodyText);
            } else {
              Helpers.showAlert('Failed PATCH to /channels:id');
            }
          }
        );
    },
    toggleAllchecked(event) {
      if (event.target.checked) {
        // select all
        this.channels.forEach((ch) => {
          if (
            this.checkedIDs.includes(ch.ID) === false &&
            (this.getSessionObj.userID === ch.OwnerID ||
              this.isAdmin())
          ) {
            this.checkedIDs.push(ch.ID);
          }
        });
      } else {
        // unselect all
        this.checkedIDs = [];
      }
    },
    performDelete() {
      this.$bvModal
        .msgBoxConfirm('Are you sure?  This action can not be undone.')
        .then((value) => {
          console.log('DELETE CONFIRMATION VALUE: ', value);

          if (!value) {
            return;
          }

          this.checkedIDs.forEach((id) => {
            this.deleteChannel(id);
          });
          this.checkedIDs = [];
          if (this.deleteAllCheckBox) {
            this.deleteAllCheckBox = false;
          }
        })
        .catch((err) => {
          // An error occurred
          // skip delete
          console.log(err);
        });
    },
    deleteChannel(id) {
      console.log('deleting channel');
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = this.getSessionObj.apiURL;
      this.$http
        .delete(url + '/channels/' + id, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function(response) {
            console.log('Success deleting channels: ', response.bodyText);
            this.fetchChannels(); // slow way. TODO: just remove from local channels []
            Helpers.showAlert('Channel deleted');
          },
          (response) => {
            // Fail
            console.log('Failed DELETE to /channels:id', response.bodyText);
            if (response.body) {
              Helpers.showAlert('Error: ' + response.bodyText);
            } else {
              Helpers.showAlert('Failed DELETE to /channels:id');
            }
          }
        );
    }, // end delete channel
    handleChannelDeletedAction(msg) {
      console.log('handleChannelDeletedAction called');
      Helpers.showAlert(msg);
      this.fetchChannels();
    },
    fetchChannels() {
      this.$store.dispatch('apiGetData', {
        url: '/channels',
        commit: true,
        stateProperty: 'channels',
        responseProperty: 'Items',
        errorAlert: 'ERROR: Could not retrieve channels',
      }).then((ch) => {
        ch.Items.sort((a, b) => a.Name.localeCompare(b.Name));
      });
    },
    isAdmin() {
      return Helpers.isAdmin();
    },
    toggleAndSaveFilter() {
      this.filterAll = !this.filterAll;
      const parsed = JSON.stringify(this.filterAll);
      localStorage.setItem('channelsFilterAll' + this.getSessionObj.userID, parsed);
    }
  }, // end methods:
  filters: {
    // capitalize: function (value) {
    //   if (!value) return ''
    //   value = value.toString()
    //   return value.charAt(0).toUpperCase() + value.slice(1)
    // },
    formatDate(value) {
      if (value) {
        //return moment(String(value)).format('MM/DD/YYYY hh:mm')
        return moment(String(value)).format('YYYY-MM-DD');
      }
    },
  },
  created: function() {
    Helpers.doViewCreation(this);

    messageBus.$on('channel-deleted', this.handleChannelDeletedAction);

    this.fetchChannels();

    let rawCFA = localStorage.getItem('channelsFilterAll' + this.getSessionObj.userID);
    if(rawCFA) {
      this.filterAll = JSON.parse(localStorage.getItem('channelsFilterAll' + this.getSessionObj.userID));
    }
  },
  mounted: function() {},
  components: { SearchBox },
};
</script>
