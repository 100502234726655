<template>
  <div ref="alertDiv" class="alert alert-warning" role="alert" v-bind:style="{ display: displayStyle  }">
    <!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button> -->
    <button ref="alertButton" type="button" class="close"  aria-label="Close" v-on:click="hideTheThing();"><span aria-hidden="true">&times;</span></button>
    <strong>{{ message }}</strong> 
  </div>
  
</template>

<script>
// @ is an alias to /src
import Helpers from "@/helpers.js";

export default {
  name: "Alert",
  props: ["message"],
  data() {
    return {
      msg: "I am an alert",
      showAlert: true,
      hideAlert: 0,
      displayStyle: "",
      lastMessage: "",
      skipNext: false
    };
  },
  methods: {
    hideTheThing() {
      this.displayStyle = "none";
      Helpers.clearAlert();
    }
  },
  components: {},
  mounted() {
    console.log("Mounted called", this.showAlert, this.hideAlert);
    this.lastMessage = this.message;
  },
  created() {
    console.log("Created called", this.showAlert, this.hideAlert);
  },
  updated() {
    console.log("Updated: message, last", this.message, this.lastMessage);

    if (this.lastMessage == this.message) {
      console.log("same message, skip");
    } else {
      this.displayStyle = "block";
      this.lastMessage = this.message;
    }
  } // end updated()
};
</script>
