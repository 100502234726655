import Vue from 'vue';
import Vuex from 'vuex';
import appStore from '@/store/appStore';
import 'es6-promise/auto';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    appStore,
  },
});
