import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/Login.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: {
        name: 'login',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/users',
      name: 'users',
      // component: Users,
      component: () =>
        import(/* webpackChunkName: "user-group" */ '@/views/Users.vue'),
    },
    {
      path: '/adduser',
      name: 'adduser',
      // component: AddUsers,
      component: () =>
        import(/* webpackChunkName: "user-group" */ '@/views/AddUser.vue'),
    },
    {
      path: '/sessioninfo',
      name: 'sessioninfo',
      // component: SessionInfo,
      component: () =>
        import(/* webpackChunkName: "sessioninfo" */ '@/views/SessionInfo.vue'),
    },
    {
      path: '/home',
      name: 'home',
      // component: Home,
      component: () =>
        import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    },
    {
      path: '/projects',
      name: 'projects',
      //component: Projects,
      component: () =>
        import(/* webpackChunkName: "projects-group" */ '@/views/Projects.vue'),
    },
    {
      path: '/projects/:id',
      name: 'projectdetail',
      // component: ProjectDetail,
      component: () =>
        import(
          /* webpackChunkName: "projects-group" */ '@/views/ProjectDetail.vue'
        ),
    },
    {
      path: '/projects/:ProjectID/layers/:id',
      name: 'layerdetail',
      // component: LayerDetail,
      component: () =>
        import(
          /* webpackChunkName: "projects-group" */ '@/views/LayerDetail.vue'
        ),
      props: (route) => ({
        query: route.query.projectName, // enable a query parameter option named projectName
      }),
    },
    {
      path: '/projects/:projectID/layers/:layerID/edit',
      name: 'styleeditor',
      // component: StyleEditor,
      component: () =>
        import(
          /* webpackChunkName: "projects-group" */ '@/views/StyleEditor.vue'
        ),
      props: true,
      // props: route => ({
      //   projectID: route.params.ProjectID,
      //   layerID: route.params.id,
      // }),
    },

    {
      path: '/addproject',
      name: 'addproject',
      // component: AddProject,
      component: () =>
        import(
          /* webpackChunkName: "projects-group" */ '@/views/AddProject.vue'
        ),
    },
    {
      path: '/projects/:id/addlayer',
      name: 'addlayer',
      // component: AddLayer,
      component: () =>
        import(
          /* webpackChunkName: "projects-group" */ '@/views/AddLayer.vue'
        ),
    },
    {
      path: '/channels',
      name: 'channels',
      // component: Channels,
      component: () =>
        import(/* webpackChunkName: "channels-group" */ '@/views/Channels.vue'),
    },
    {
      path: '/addchannel',
      name: 'addchannel',
      // component: AddChannel,
      component: () =>
        import(
          /* webpackChunkName: "projects-group" */ '@/views/AddChannel.vue'
        ),
    },
    {
      path: '/channels/:id',
      name: 'channeldetail',
      // component: ChannelDetail,
      component: () =>
        import(
          /* webpackChunkName: "channels-group" */ '@/views/ChannelDetail.vue'
        ),
    },
    {
      path: '/channels/:channelID/geofeatures/:geofeatureID',
      name: 'geofeaturedetail',
      // component: GeofeatureDetail,
      component: () =>
        import(
          /* webpackChunkName: "channels-group" */ '@/views/GeofeatureDetail.vue'
        ),
    },
    {
      path: '/users/:id',
      name: 'userdetail',
      // component: UserDetail,
      component: () =>
        import(/* webpackChunkName: "user-group" */ '@/views/UserDetail.vue'),
    },
    {
      path: '/myaccount',
      name: 'myaccount',
      // component: MyAccount,
      component: () =>
        import(/* webpackChunkName: "myaccount" */ '@/views/MyAccount.vue'),
    },
    {
      path: '/updateaccount',
      name: 'updateaccount',
      // component: UpdateAccount,
      component: () =>
        import(
          /* webpackChunkName: "user-group" */ '@/views/UpdateAccount.vue'
        ),
    },
    {
      path: '/devtest',
      name: 'devtest',
      // component: DevTest,
      component: () =>
        import(/* webpackChunkName: "devtest" */ '@/views/DevTest.vue'),
    },
    {
      path: '/downloads',
      name: 'downloads',
      // component: DevTest,
      component: () =>
        import(/* webpackChunkName: "devtest" */ '@/views/ClientDownloads.vue'),
    },
    {
      path: '/sprites',
      name: 'sprites',
      // component: Sprites,
      component: () =>
        import(/* webpackChunkName: "devtest" */ '@/views/Sprites.vue'),
    },
    {
      path: '/sprites/:id',
      name: 'spritedetail',
      // component: Sprites,
      component: () =>
        import(/* webpackChunkName: "devtest" */ '@/views/SpriteDetail.vue'),
    },
    {
      path: '/shapes',
      name: 'shapes',
      // component: Shapes,
      component: () =>
        import(/* webpackChunkName: "devtest" */ '@/views/Shapes.vue'),
    },
    {
      path: '/shapes/:id',
      name: 'shapedetail',
      // component: Sprites,
      component: () =>
        import(/* webpackChunkName: "devtest" */ '@/views/ShapeDetail.vue'),
    },
    {
      path: '/remoteprovisioning',
      name: 'remoteprovisioning',
      // component: RemoteProvisioning,
      component: () =>
        import(/* webpackChunkName: "provisioning" */ '@/views/RemoteProvisioning.vue'),
    },
    {
      path: '/website',
      beforeEnter() {
        switch (process.env.VUE_APP_THEME) {
          case 'edxw':
            location.href = 'http://www.edx.com';
            break;
          default:
            location.href = 'https://www.edxtech.com';
        }
      },
    },
    {
      path: '/support',
      name: 'support',
      // route level code-splitting
      // this generates a separate chunk (support.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "support" */ '@/views/Support.vue'),
    },
    {
      // catch all for not founds
      path: '*',
      // component: PageNotFound,
      component: () =>
        import(
          /* webpackChunkName: "pagenotfound" */ '@/views/PageNotFound.vue'
        ),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    // see: https://router.vuejs.org/guide/advanced/scroll-behavior.html#async-scrolling
    // return desired position
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
