<template>
  <div id="app">
    <div v-if="!this.appDefaultApiUrl" class="f6">Fetching config...</div>

    <div v-if="this.appDefaultApiUrl">
      <!-- SIDE NAV BAR --------------------- NOT CURRENTLY IN USE -->
      <!-- <div id="mySidenav" class="sidenav" v-if="isAuthenticated">
          <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
          <router-link v-if="isAuthenticated" to="/home">Home</router-link>
          <router-link v-if="isAuthenticated" to="/users">Users</router-link>
          <router-link v-if="isAuthenticated" to="/projects">Projects</router-link>
          <router-link v-if="isAuthenticated" to="/sessioninfo">Server Info</router-link>
          <router-link v-if="isAuthenticated" to="/support">Support</router-link>
          <router-link v-if="isAuthenticated" to="/login" v-on:click.native="logout()" replace> Logout <span class="f7">({{sessionInfo.username}})</span></router-link>
      </div> -->

      <!-- TOP MOST HEADER BAR, CONTAINS LOGO, LOGOUT BUTOTN -->

      <nav class="dt w-100 border-box pa2 ph3-ns bg-light-gray">
        <a class="dtc v-mid mid-gray link dim w-25" href="/home" title="Home">
          <!-- <img src="./assets/logo.png" class="dib w3 br0" alt="Site Name"> -->
          <!-- <span v-html=></span> -->
          <img src="@/assets/logo.png" class="dib w3 br0" alt="Site Name" />
        </a>

        <div class="dtc v-mid w-50">
          <center>
            <span v-if="isAuthenticated" class="tc f6 i"
              >API: {{ getSessionObj.apiURL }}
            </span>
          </center>
        </div>

        <div class="dtc v-mid w-75 tr">
          <router-link
            v-if="!isAuthenticated"
            to="/support"
            class="hover-gold dark-gray f6 f5-ns dib mr3 mr4-ns"
            >Help</router-link
          >
          <span
            v-if="isAuthenticated"
            class="f7 tracked mr2"
            v-b-tooltip.hover
            :title="'ID: ' + getSessionObj.userID"
            >{{ getSessionObj.username }}</span
          ><span
            ><router-link
              v-if="isAuthenticated"
              to="/login"
              class="shadow-1 ph2 br3 hover-gold dark-gray f6 f5-ns dib mr3 mr4-ns"
              v-on:click.native="logout()"
              replace
              >Log out</router-link
            ></span
          >
          <!-- <div class="dib">
                  <p class="f7 mr2 dib tc v-btm">
                      <span class="ttu tracked">{{sessionInfo.username}}</span>

                  </p>
                  <img v-if="isAuthenticated" src="./assets/avatar-default.jpeg" class="br-100 pa1 ba b--black-10" style="width: 40px; height: 40px" alt="avatar">
              </div> -->
        </div>
      </nav>

      <!-- SIDE NAV BAR OPEN/CLOSE BUTTON ------------------ NOT CURRENTLY IN USE -->
      <!-- <span v-if="isAuthenticated" style="font-size:30px;cursor:pointer" onclick="openNav()">&#9776;⬅  Menu</span> -->

      <!-- <a href="/home" class="pa0 db ml2 h-100 w-10 flex-none">
      <img src="./assets/logo.png" alt="Corporate logo" class="br0 db mb0 w-100"/>
    </a> -->

      <!-- ORIGINAL CONTENT -->
      <!-- top bar navigation WITH LINKS TO CLICK TO GO PLACES, THIS IS IN USE -->
      <div class="">
        <center>
          <router-link v-if="isAuthenticated" to="/home" class="gray hover-gold"
            >Home</router-link
          >
          <span v-if="isAuthenticated"> | </span>
          <router-link
            v-if="isAuthenticated"
            to="/users"
            class="gray hover-gold"
            >Users</router-link
          >
          <span v-if="isAuthenticated"> | </span>
          <router-link
            v-if="isAuthenticated"
            to="/projects"
            class="gray hover-gold"
            >Projects</router-link
          >
          <span v-if="isAuthenticated"> | </span>
          <router-link
            v-if="isAuthenticated"
            to="/channels"
            class="gray hover-gold"
            >Channels</router-link
          >
          <span v-if="isAuthenticated"> | </span>

          <div class="dropdown" v-if="isAuthenticated">
            <div class="nav-a gray" style="display: inline-block">Assets</div>
            <div class="dropdown-content">
              <router-link to="/shapes" class="gray hover-gold"
                >Shapes</router-link
              >
              <router-link to="/sprites" class="gray hover-gold"
                >Sprites</router-link
              >
            </div>
          </div>

          <span v-if="isAuthenticated"> | </span>

          <router-link
            v-if="isAuthenticated"
            to="/sessioninfo"
            class="gray hover-gold"
            >API Session</router-link
          >
          <span v-if="isAuthenticated"> | </span>
          <router-link
            v-if="isAuthenticated"
            to="/myaccount"
            class="gray hover-gold"
            >My Account</router-link
          >
          <span v-if="isAuthenticated"> | </span>
          <!-- <router-link v-if="isAuthenticated" to="/devtest" class="gray hover-gold">devtest</router-link>
          <span v-if="isAuthenticated"> | </span> -->
          <router-link
            v-if="isAuthenticated"
            to="/support"
            class="gray hover-gold"
            >Support</router-link
          >

          <span v-if="isAuthenticated"> | </span>
          <router-link
            v-if="isAuthenticated"
            to="/downloads"
            class="gray hover-gold"
            >Downloads</router-link
          >

          <span v-if="isAdmin()"> | </span>
          <router-link
            v-if="isAdmin()"
            to="/remoteprovisioning"
            class="gray hover-gold"
            >Remote Provisioning</router-link
          >

          <!-- <span v-if="isAuthenticated"> | </span> -->
          <!-- <router-link v-if="isAuthenticated" to="/login" v-on:click.native="logout()" replace> Logout <span class="f7">({{sessionInfo.username}})</span></router-link> -->
          <!-- <router-link v-else to="/login" v-on:click.native="logout()" replace>Login</router-link> -->

          <!-- <Alert message="test" /> -->
          <Alert v-if="alert" v-bind:message="alert"></Alert>
        </center>
      </div>

      <!-- THIS MAKES ALL THE MAGIC HAPPEN OF BEING LOGGED IN -- DON'T REMOVE -->
      <router-view @authenticated="setAuthenticated" />

      <!-- We don't want to show these views, but we want them to fetch data
      This is VERY ineffecient, but it gets us our dashboard stats -->
      <Home v-if="isAuthenticated" v-show="false"></Home>
      <Users v-if="isAuthenticated" v-show="false"></Users>
      <Projects v-if="isAuthenticated" v-show="false"></Projects>
      <Channels v-if="isAuthenticated" v-show="false"></Channels>

      <!-- <Login v-if="isAuthenticated" v-show="false"></Login>
      <Home v-if="isAuthenticated" v-show="false"></Home>
      <SessionInfo v-if="isAuthenticated" v-show="false"></SessionInfo>
      <Support v-if="isAuthenticated" v-show="false"></Support>
      <Support v-if="isAuthenticated" v-show="false"></Support> -->
    </div>
    <!-- end waiting for apiURL -->
  </div>
  <!-- End template div-->
</template>

<script>
//import { messageBus } from '@/main'
import { messageBus } from "@/main";
import Home from "@/views/Home.vue";
import Users from "@/views/Users.vue";
import Projects from "@/views/Projects.vue";
import Channels from "@/views/Channels.vue";
import Alert from "@/components/Alert.vue";
import { mapGetters, mapMutations } from "vuex";
import router from "@/router";
import Helpers from "@/helpers.js";

router.afterEach((/*to, from*/) => {
  if (document) document.activeElement.blur();
});

export default {
  components: {
    Users,
    Projects,
    Channels,
    Home,
    Alert,
  },
  name: "App",
  data() {
    return {
      lastMessageFromBus: 0,
      appGitVersion: "",
      appDefaultApiUrl: null,
      alert: "",
      NAV_HOTKEYS: ["h", "u", "p", "c", "a", "m", "s", "d", "r"],
    };
  },
  computed: mapGetters(["getSessionObj", "isAuthenticated"]),
  mounted() {
    document.addEventListener("keydown", this.onHotkey);
    this.doThemeThings();
    if (!this.isAuthenticated && this.$route.name != "login") {
      this.$router.replace({ name: "login" });
    } else {
      // this.openNav();
    }
  },
  methods: {
    ...mapMutations(["MUTATE", "RESET_STATE"]),
    setAuthenticated(
      status,
      tokenPayload,
      access_token,
      token_type,
      expires_in,
      apiURL,
      username,
      userID
    ) {
      let sessionInfo = {};
      sessionInfo.tokenPayload = tokenPayload;
      sessionInfo.roles = tokenPayload.rol;
      sessionInfo.access_token = access_token;
      sessionInfo.token_type = token_type;
      sessionInfo.apiURL = apiURL;
      sessionInfo.username = username;
      sessionInfo.expires_in = expires_in;
      sessionInfo.userID = userID;
      // Let's save when the token expires
      let expires_at = new Date(tokenPayload.exp * 1000);
      sessionInfo.expires_at = expires_at;

      // NEW STATE STORE
      this.MUTATE({ property: "sessionInfo", with: sessionInfo });
      this.MUTATE({ property: "authenticated", with: status });

      // save sessionInfo to local storage to remain logged in across browser sessions if desired
      console.log("Saving session info");
      const parsed = JSON.stringify(this.getSessionObj);
      localStorage.setItem("sessionInfo", parsed);

      // this.openNav();

      // get our version info
      this.fetchAppBuildID();

      if (status === true) {
        // authenticated was successfull
        this.prefetchAppDate(); // go ahead and load data from our API
      }
    },
    logout(logoutMsg /* optional */) {
      // NEW STATE STORE
      this.MUTATE({ property: "authenticated", with: false });
      this.RESET_STATE();

      // delete sessionInfo from local storage as we are logging out
      localStorage.removeItem("sessionInfo");

      // make sure no matter where we came from we navigate back to login screen.
      if (logoutMsg) {
        this.$router.push({ path: "/login", query: { alert: logoutMsg } });
      } else {
        this.$router.push({ path: "/login" });
      }

      //this.closeNav();
    },
    isAdmin() {
      return Helpers.isAdmin();
    },
    onHotkey(e) {
      if (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) return;
      if (this.$route.name == "login") return;

      if (e.srcElement === document.body && this.NAV_HOTKEYS.includes(e.key)) {
        e.preventDefault();
        let routePathToGoTo = "/";
        switch (e.key) {
          case "h":
            routePathToGoTo = "/home";
            break;
          case "u":
            routePathToGoTo = "/users";
            break;
          case "p":
            routePathToGoTo = "/projects";
            break;
          case "c":
            routePathToGoTo = "/channels";
            break;
          case "a":
            routePathToGoTo = "/sessioninfo";
            break;
          case "m":
            routePathToGoTo = "/myaccount";
            break;
          case "s":
            routePathToGoTo = "/support";
            break;
          case "d":
            routePathToGoTo = "/downloads";
            break;
          case "r":
            routePathToGoTo = "/remoteprovisioning";
            break;
        }
        if (this.$route.path != routePathToGoTo) {
          this.$router.push({ path: routePathToGoTo });
        }
      }
    },
    prefetchAppDate() {
      this.$store.dispatch("apiGetData", {
        url: "/channels",
        commit: true,
        stateProperty: "channels",
        responseProperty: "Items",
        statePropertyLoaderToToggle: "loadingChannels",
      });
      this.$store.dispatch("apiGetData", {
        url: "/users",
        commit: true,
        stateProperty: "users",
        responseProperty: "Items",
        statePropertyLoaderToToggle: "loadingUsers",
      });
      this.$store.dispatch("apiGetData", {
        url: "/projects",
        commit: true,
        stateProperty: "projects",
        responseProperty: "Items",
        statePropertyLoaderToToggle: "loadingProjects",
      });
      this.$store.dispatch("apiGetData", {
        url: "/buildinfo",
        commit: true,
        stateProperty: "buildInfo",
        statePropertyLoaderToToggle: "loadingBuildInfo",
      });
    },
    setLastEventMessage(msg) {
      this.lastMessageFromBus = msg;
    },
    openNav() {
      document.getElementById("mySidenav").style.width = "175px";
      document.getElementById("main").style.marginLeft = "175px";
    },
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
      document.getElementById("main").style.marginLeft = "0";
    },
    eventHandlerLogout(msg) {
      console.log("RECEIVED EVENT MSG LOGOUT: ", msg);
      this.logout(msg);
    },
    eventShowAlert(msg) {
      console.log("Alert message event: ", msg);
      this.alert = msg;
    },
    eventClearAlert(/* msg */) {
      // Alert component will call this function to clear alert when user closes
      // the alert.  If we don't do this, then sending the same alert after
      // the user closes the alert box, it won't show up.
      this.alert = "";
    },
    fetchAppBuildID() {
      if (!this.isAuthenticated) {
        return;
      }
      console.log("FETCHING BUILD ID VERSION");
      this.MUTATE({ property: "loadingVersion", with: true });
      let url = this.$router.options.base + "version.txt";
      console.log("URL: ", url);
      this.$http.get(url).then(
        function (response) {
          console.log("Success getting /version.txt", response.body);
          this.appGitVersion = response.body;
          // NEW STORE
          this.MUTATE({ property: "version", with: response.body });
        },
        (response) => {
          // Fail
          console.log("Failed to get /version.txt ", response.body);
        }
      );
      this.MUTATE({ property: "loadingVersion", with: false });
    },
    fetchAppDefaultApiUrl() {
      console.log("FETCHING DEFAULT APP API URL");
      let url = this.$router.options.base + "apiurl.txt";
      console.log("API URL: ", url);
      this.$http.get(url).then(
        function (response) {
          console.log("Success getting /apiurl.txt", response.body);
          this.appDefaultApiUrl = response.body;
        },
        (response) => {
          // Fail
          console.log("Failed to get /apiurl.txt ", response.body);
        }
      );
    },
    doThemeThings() {
      let company = document.getElementById("poweredbyID");
      let website = document.getElementById("websiteID");

      //console.log("Current Theme :: ", typeof process.env.VUE_APP_THEME, process.env.VUE_APP_THEME)
      switch (process.env.VUE_APP_THEME) {
        case "edxw":
          document.title = "EDX | ACX Service Console";
          company.innerHTML =
            "<span class='white'>Powered by: © " +
            new Date().getFullYear() +
            " EDX Wireless, Inc</span>";
          website.innerHTML = "www.edx.com";
          //websiteContact.innerHTML = "https://www.edx.com/contact";
          //supportContact.innerHTML = "support@edx.com";

          document.body.style.setProperty("--my-primary-color", "#073682");
          document.body.style.setProperty("--my-link-hover-color", "darblue");
          document.body.style.setProperty("--my-bg-gold", "darkgray");
          // document.querySelectorAll(".hover-gold").className = ".hover-blue";
          // document.getElementsByClassName('gray hover-gold').className = 'hover-blue';

          break;
        default:
          company.innerHTML =
            "Powered by: © " +
            new Date().getFullYear() +
            " EDX Technologies, Inc";
          website.innerHTML = "www.edxtech.com";
          document.body.style.setProperty("--my-primary-color", "#fcb006");
      }

      //this.$router.replace('/home')
    }, // end doThemeThings
    // getLogo() {
    //     const url = require(`@/assets/logo.png`);
    //     return url;
    // }
  },
  created() {
    this.fetchAppDefaultApiUrl();

    if (localStorage.getItem("sessionInfo")) {
      console.log("Restoring session info");
      let sessionInfo = JSON.parse(localStorage.getItem("sessionInfo"));
      console.log(sessionInfo);

      // check to see if our token is expired.
      let expires_at = new Date(sessionInfo.expires_at);
      let now = new Date();
      if (!expires_at || now > expires_at) {
        // NEW STATE STORE
        this.MUTATE({ property: "authenticated", with: false });
        return;
      }

      // otherwise we will try to reuse our last stored session (as the user never logged out)
      // NEW STATE STORE
      this.MUTATE({ property: "sessionInfo", with: sessionInfo });
      this.MUTATE({ property: "authenticated", with: true });

      this.fetchAppBuildID(); // go ahead and load data
      this.prefetchAppDate(); // go ahead and load data from our API
    }

    messageBus.$on("logout", this.eventHandlerLogout);

    // event to process, followed by function to process it
    messageBus.$on("show-alert", this.eventShowAlert);

    messageBus.$on("clear-alert", this.eventClearAlert);
  },
  beforeDestroy() {
    //this.closeNav();
  },
  updated() {},
};
</script>

<style lang="stylus" scoped>
@css {
  .dropdown {
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    background-color: #f0f0f0;
    text-decoration: none;
    display: block;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
    text-decoration: underline;
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  /* END trying dropdown */
}
</style>
