import { messageBus } from '@/main.js';
import { myVm } from '@/main.js';
import store from './store/store';

import { latLng } from 'leaflet';

export default {
  emitEvent(event, data) {
    messageBus.$emit(event, data);
  },
  // This function decodes a JWT token, the returns
  // the Object that is the payload
  //  When importing, do:
  //      import decodeToken from '@/helpers.js'
  //      then call the function as normal, e.g. decodeToken(token)
  //      you'll get an object back
  decodeToken(token) {
    if (token.startsWith('Bearer ')) {
      // Remove Bearer from string
      token = token.slice(7, token.length).trimLeft();
    }
    if (token) {
      let encodedPayload = token.split('.')[1];
      let decodedPayload = JSON.parse(window.atob(encodedPayload));
      return decodedPayload;
    }
    return null;
  },
  // formats a Date object to "HR::MM:SS am/pm"
  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    return strTime;
  },
  formatHoursMinutesSeconds(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ':' + seconds;
    return strTime;
  },
  // Converts a Date() object into local timezone
  formatToLocalTime(time) {
    var d = new Date(time);
    var offset = (new Date().getTimezoneOffset() / 60) * -1;
    var n = new Date(d.getTime() + offset);
    return n;
  },
  secondsToHms(d) {
    d = Number(d / 1000);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
    return hDisplay + mDisplay + sDisplay;
  },

  // Check if an access_token is expired or going to expire
  // within next the next 30 minutes.
  // Returns true/false
  // Pass in the "minutes" NOT seconds, of expire time for
  // the token from Unix epoch, which is exactly what the
  // Kong JWT crafter gives us back in the /tokens API call
  isTokenAboutToExpire(timeTokenExpires) {
    let now = new Date();
    let then = new Date(timeTokenExpires * 1000); // multiple by 1000
    if (now > then || then - now < 30) {
      // if expired or expiring within 30 minutes
      // Token is expired or expiring.
      return true;
    }
    return false;
  },
  isTokenExpired(timeTokenExpires) {
    let now = new Date();
    let then = new Date(timeTokenExpires * 1000); // must multiple by 1000
    if (now > then) {
      return true;
    }
    return false;
  },
  errorMsgTokenExpired() {
    return 'Your session expired.';
  },
  doViewCreation(passedInThis) {
    // objects and arrays are pass-by-reference in javascript

    if (!passedInThis) {
      messageBus.$emit(
        'show-alert',
        'Error: doViewCreation called with invalid parameters'
      );
    }
    if (passedInThis.$route.query.alert) {
      //this.alert = this.$route.query.alert;
      messageBus.$emit('show-alert', passedInThis.$route.query.alert);
    } else {
      messageBus.$emit('clear-alert');
    }
  },
  showAlert(text) {
    messageBus.$emit('show-alert', text);
  },
  clearAlert() {
    messageBus.$emit('clear-alert', 'Clear alert event emitted');
  },
  logout(msg) {
    if (msg) {
      messageBus.$emit('logout', msg);
    } else {
      messageBus.$emit('logout', '');
    }
  },
  isAdminOrOwner(OwnerID) {
    let adminBoolean = this.isAdmin();
    let ownerBoolean = store.getters.getSessionObj.userID === OwnerID ? true : false;
    return (adminBoolean || ownerBoolean);
  },
  isAdmin() {
    let roles = store.getters.getSessionObj.roles;
    if (!roles) {
      this.showAlert(
        'Bad function parameter, helpers. Contant an administrator.'
      );
    }

    for (let i = 0; i < roles.length; i++) {
      if (roles[i] === 'ADMIN') {
        return true;
      }
    }
    return false;
  },
  // input should be a string
  // return value is the base64encoded string
  base64Encode(s) {
    let se = Buffer.from(s.toString()).toString('base64');

    return se;
  },
  base64Decode(se) {
    let s = new Buffer(se, 'base64');
    return s.toString('ascii');
  },
  test() {
    // test to see if I can access the main Vue sessionInfo variable from this
    // utility module.  It seems to work!
    console.log(myVm.$children[0].sessionInfo);
  },
  parseGeofeature(geofeature) {
      if (
        geofeature.Geometry.Type === 'RSnap' ||
        geofeature.Geometry.Type === 'RVid'
      ) {
        return {
          type: 'point',
          location: [
            latLng(
              geofeature.Geometry.GeoPose.Location.LatD,
              geofeature.Geometry.GeoPose.Location.LngD,
            ),
          ],
          geofeature: geofeature,
        };
      } else if (
        geofeature.Geometry.Type === 'PointHeight3D' ||
        geofeature.Geometry.Type === 'Point3D'
      ) {
        return {
          type: 'point',
          location: [
            latLng(
              geofeature.Geometry.LatLngElev.LatD,
              geofeature.Geometry.LatLngElev.LngD,
            ),
          ],
          geofeature: geofeature,
        };
      } else if (geofeature.Geometry.Type === 'LineString3D') {
        return {
          type: 'line',
          location: [
            latLng(
              geofeature.Geometry.Points[0].LatD,
              geofeature.Geometry.Points[0].LngD,
            ),
          ],
          locations: geofeature.Geometry.Points.map(location =>
            latLng(location.LatD, location.LngD),
          ),
          geofeature: geofeature,
        };
      }
      console.log('cannot parse geofeature', geofeature);
      return null;
    },
};
